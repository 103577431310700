import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import arLocale from './lang/ar';

console.log(arLocale);

const i18n = createI18n({
    locale: 'ar',
    messages: {
        ar: arLocale
    }
});

const app = createApp(App);

app.use(router);
app.use(i18n);
app.mount('#app');
//createApp(App).use(router).mount('#app')
