export default {
    title: 'عداد وقت',
    setting: 'ضبط',
    start: 'بداية',
    pomodoro: 'بومودورو',
    short_break: 'استراحة قصيرة',
    long_break: 'استراحة طويلة',
    timer_setting: 'ضبط الوقت',
    time_minutes: 'الوقت (بالدقائق)',
    auto_start_breaks: 'فواصل بدء التشغيل التلقائي؟',
    auto_start_pomodoros: 'بدء تلقائي بومودوروس؟',
    alarm_sound: 'صوت المنبه',
    ticking_sound: 'صوت التنقيط؟',
    none: 'لا أحد',
    bell: 'جرس',
    bird: 'عصفور',
    digital: 'رقمي',
    kitchen: 'مطبخ',
    wood: 'خشب',
    ticking_slow: 'تنقيط بطيء',
    ticking_fast: 'تنقيط بسرعة',
    ok: 'موافق',
    an_online_pomodoro_timer_to_boost_your_productivity: 'مؤقت بومودورو عبر الإنترنت لزيادة إنتاجيتك',
    what_is_pomodoroy: 'ما هو بومودوروي؟',
    what_is_pomodoroy_answer_part_1: 'وقت هو مؤقت بومودورو قابل للتخصيص يعمل على متصفح سطح المكتب والجوال. الهدف من هذا التطبيق هو مساعدتك في التركيز على أي مهمة تعمل عليها ، مثل الدراسة أو الكتابة أو البرمجة. هذا التطبيق مستوحى من',
    pomodoro_technique: 'تقنية بومودورو',
    what_is_pomodoroy_answer_part_2: 'وهي طريقة لإدارة الوقت طورها Francesco Cirillo.',
    what_is_pomodoro_technique: 'ما هي تقنية بومودورو؟',
    what_is_pomodoro_technique_answer: 'تم إنشاء تقنية بومودورو بواسطة Francesco Cirillo من أجل طريقة أكثر إنتاجية للعمل والدراسة. تستخدم هذه التقنية مؤقتًا لتقسيم العمل إلى فترات زمنية ، يبلغ طولها تقليديًا 25 دقيقة ، مفصولة بفواصل قصيرة. تُعرف كل فترة زمنية باسم بومودورو ، من الكلمة الإيطالية التي تعني "طماطم" ، بعد مؤقت المطبخ على شكل الطماطم الذي استخدمه سيريلو كطالب جامعي. -',
    wikipedia: 'ويكيبيديا',
    how_to_use_pomodoro_timer: 'كيفية استخدام مؤقت بومودورو؟',
    how_to_use_pomodoro_timer_answer: 'ابدأ المؤقت وركز على مهمة لمدة 25 دقيقة. خذ استراحة لمدة 5 دقائق عندما يرن المنبه. كرر 3-5 حتى تنتهي من المهام',
    features: 'سمات',
    responsive_design: 'الرسم المتجاوب',
    responsive_design_line: 'يعمل مع سطح المكتب والجوال',
    color_transition: 'انتقال اللون',
    color_transition_line: 'لتبديل الحالة المزاجية بين وقت العمل ووقت الراحة',
    audio_notification: 'إخطار صوتي',
    audio_notification_line: 'في نهاية فترة المؤقت',
    customizable_timer: 'مؤقت قابل للتخصيص',
    customizable_timer_line: 'فترات زمنية لتناسب تفضيلاتك',
    home: 'الصفحة الرئيسية',
    privacy: 'الإجمالية',
    contact: 'اتصل',
    simple_page: 'صفحة بسيطة',
    all_rights_reserved: 'كل الحقوق محفوظة',
    privacy_policy: 'سياسة الخصوصية',
    privacy_policy_effectivity: 'تاريخ السريان: 14 يوليو 2019',
    privacy_policy_paragraph_1: 'يقوم Pomodoroy ("نحن" أو "نحن" أو "خاصتنا") بتشغيل موقع https://pomodoroy.io على الويب (يشار إليه فيما يلي باسم "الخدمة").',
    privacy_policy_paragraph_2: 'تُعلمك هذه الصفحة بسياساتنا المتعلقة بجمع البيانات الشخصية واستخدامها والكشف عنها عند استخدام خدمتنا والخيارات التي قمت بربطها بهذه البيانات.',
    privacy_policy_paragraph_3: 'نستخدم بياناتك لتوفير الخدمة وتحسينها. باستخدام الخدمة ، فإنك توافق على جمع واستخدام المعلومات وفقًا لهذه السياسة. ما لم يتم تحديد خلاف ذلك في سياسة الخصوصية هذه ، فإن المصطلحات المستخدمة في سياسة الخصوصية هذه لها نفس المعاني كما في الشروط والأحكام الخاصة بنا ، والتي يمكن الوصول إليها من https://pomodoroy.io',
    information_collection_and_use: 'جمع المعلومات واستخدامها',
    information_collection_and_use_paragraph_1: 'نقوم بجمع عدة أنواع مختلفة من المعلومات لأغراض مختلفة لتوفير وتحسين خدماتنا لك.',
    types_of_data_collected: 'أنواع البيانات المجمعة',
    personal_data: 'بيانات شخصية',
    personal_data_paragraph_1: 'أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا بمعلومات تعريف شخصية معينة يمكن استخدامها لتحديد هويتك. قد تتضمن معلومات التعريف الشخصية ، على سبيل المثال لا الحصر:',
    cookies_and_usage_data: 'ملفات تعريف الارتباط وبيانات الاستخدام',
    usage_data: 'بيانات الاستخدام',
    usage_data_paragraph_1: 'قد نقوم أيضًا بجمع معلومات حول كيفية الوصول إلى الخدمة واستخدامها ("بيانات الاستخدام"). قد تتضمن بيانات الاستخدام هذه معلومات مثل عنوان بروتوكول الإنترنت لجهاز الكمبيوتر الخاص بك (مثل عنوان IP) ، ونوع المتصفح ، وإصدار المتصفح ، وصفحات خدمتنا التي تزورها ، ووقت وتاريخ زيارتك ، والوقت الذي تقضيه على تلك الصفحات ، فريد معرّفات الجهاز وبيانات التشخيص الأخرى.',
    tracking_and_cookies_data: 'بيانات التتبع وملفات تعريف الارتباط',
    tracking_and_cookies_data_paragraph_1: 'نحن نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة لتتبع النشاط على خدمتنا والاحتفاظ بمعلومات معينة.',
    tracking_and_cookies_data_paragraph_2: 'ملفات تعريف الارتباط هي ملفات تحتوي على كمية صغيرة من البيانات التي قد تتضمن معرفًا فريدًا مجهول الهوية. يتم إرسال ملفات تعريف الارتباط إلى متصفحك من موقع ويب وتخزينها على جهازك. تقنيات التتبع المستخدمة أيضًا هي إشارات وعلامات ونصوص لجمع المعلومات وتتبعها ولتحسين خدمتنا وتحليلها.',
    tracking_and_cookies_data_paragraph_3_part_1: 'يمكنك توجيه متصفحك لرفض جميع ملفات تعريف الارتباط أو للإشارة إلى وقت إرسال ملف تعريف الارتباط. ومع ذلك ، إذا كنت لا تقبل ملفات تعريف الارتباط ، فقد لا تتمكن من استخدام بعض أجزاء من خدمتنا. يمكنك معرفة المزيد عن كيفية إدارة ملفات تعريف الارتباط في ملف',
    tracking_and_cookies_data_paragraph_3_part_2: 'دليل ملفات تعريف الارتباط للمتصفح',
    tracking_and_cookies_data_paragraph_4: 'أمثلة على ملفات تعريف الارتباط التي نستخدمها:',
    session_cookies: 'ملفات تعريف ارتباط الجلسة.',
    session_cookies_line: 'نحن نستخدم ملفات تعريف الارتباط الخاصة بالجلسات لتشغيل خدمتنا.',
    preference_cookies: 'ملفات تعريف الارتباط المفضلة.',
    preference_cookies_line: 'نحن نستخدم ملفات تعريف الارتباط المفضلة لتذكر تفضيلاتك والإعدادات المختلفة.',
    security_cookies: 'ملفات تعريف الارتباط الأمنية.',
    security_cookies_line: 'نحن نستخدم ملفات تعريف الارتباط الأمنية لأغراض أمنية.',
    use_of_data: 'استخدام البيانات',
    use_of_data_paragraph_1: 'يستخدم Pomodoroy البيانات التي تم جمعها لأغراض مختلفة:',
    use_of_data_line_1: 'لتوفير وصيانة الخدمة',
    use_of_data_line_2: 'لإعلامك عن تغييرات لخدمتنا',
    use_of_data_line_3: 'للسماح لك بالمشاركة في الميزات التفاعلية لخدمتنا عندما تختار القيام بذلك',
    use_of_data_line_4: 'لتقديم خدمة ودعم العملاء',
    use_of_data_line_5: 'لتوفير تحليل أو معلومات قيمة حتى نتمكن من تحسين الخدمة',
    use_of_data_line_6: 'لمراقبة استخدام الخدمة',
    use_of_data_line_7: 'للكشف عن المشكلات الفنية ومنعها ومعالجتها',
    transfer_of_data: 'نقل البيانات',
    transfer_of_data_paragraph_1: 'قد يتم نقل معلوماتك ، بما في ذلك البيانات الشخصية ، إلى أجهزة الكمبيوتر الموجودة خارج ولايتك أو مقاطعتك أو بلدك أو الولاية القضائية الحكومية الأخرى التي قد تختلف فيها قوانين حماية البيانات عن تلك الموجودة في ولايتك القضائية.',
    transfer_of_data_paragraph_2: 'إذا كنت متواجدًا خارج اليابان واخترت تقديم معلومات إلينا ، فيرجى ملاحظة أننا نقوم بنقل البيانات ، بما في ذلك البيانات الشخصية ، إلى اليابان ومعالجتها هناك.',
    transfer_of_data_paragraph_3: 'موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لهذه المعلومات تمثل موافقتك على هذا النقل.',
    transfer_of_data_paragraph_4: 'ستتخذ Pomodoroy جميع الخطوات اللازمة بشكل معقول لضمان التعامل مع بياناتك بشكل آمن ووفقًا لسياسة الخصوصية هذه ولن يتم نقل بياناتك الشخصية إلى منظمة أو دولة ما لم تكن هناك ضوابط كافية في مكانها بما في ذلك أمنك البيانات والمعلومات الشخصية الأخرى.',
    disclosure_of_data: 'إفشاء البيانات',
    legal_requirements: 'المتطلبات القانونية',
    legal_requirements_paragraph_1: 'قد تكشف شركة Pomodoroy عن بياناتك الشخصية بحسن نية أن مثل هذا الإجراء ضروري من أجل:',
    legal_requirements_line_1: 'للامتثال لالتزام قانوني',
    legal_requirements_line_2: 'لحماية والدفاع عن حقوق أو ممتلكات بومودوروي',
    legal_requirements_line_3: 'لمنع أو التحقيق في أي مخالفات محتملة تتعلق بالخدمة',
    legal_requirements_line_4: 'لحماية السلامة الشخصية لمستخدمي الخدمة أو الجمهور',
    legal_requirements_line_5: 'للحماية من المسؤولية القانونية',
    security_of_data: 'أمن البيانات',
    security_of_data_paragraph_1: 'يعد أمان بياناتك أمرًا مهمًا بالنسبة لنا ، ولكن تذكر أنه لا توجد وسيلة نقل عبر الإنترنت أو طريقة تخزين إلكتروني آمنة بنسبة 100٪. بينما نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية ، لا يمكننا ضمان أمنها المطلق.',
    service_providers: 'مقدمي الخدمة',
    service_providers_paragraph_1: 'يجوز لنا توظيف شركات وأفراد من أطراف ثالثة لتسهيل خدمتنا ("مزودي الخدمة") ، أو لتقديم الخدمة نيابة عنا ، أو لأداء الخدمات المتعلقة بالخدمة أو لمساعدتنا في تحليل كيفية استخدام خدمتنا.',
    service_providers_paragraph_2: 'هذه الأطراف الثالثة لديها حق الوصول إلى بياناتك الشخصية فقط لأداء هذه المهام نيابة عنا وهي ملزمة بعدم الكشف عنها أو استخدامها لأي غرض آخر.',
    changes_to_this_privacy_policy: 'التغييرات على سياسة الخصوصية هذه',
    changes_to_this_privacy_policy_paragraph_1: 'قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنخطرك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.',
    changes_to_this_privacy_policy_paragraph_2: 'سنخبرك عبر البريد الإلكتروني و / أو إشعارًا بارزًا على خدمتنا ، قبل أن يصبح التغيير ساريًا وتحديث "تاريخ السريان" في الجزء العلوي من سياسة الخصوصية هذه.',
    changes_to_this_privacy_policy_paragraph_3: 'يُنصح بمراجعة سياسة الخصوصية هذه بشكل دوري لمعرفة أي تغييرات. تسري التغييرات التي تطرأ على سياسة الخصوصية هذه عند نشرها على هذه الصفحة.',
    contact_us: 'اتصل بنا',
    contact_us_paragraph_1: 'إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه ، فيرجى الاتصال بنا عن طريق إرسال بريد إلكتروني إلى:'
};